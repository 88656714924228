import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';


interface Props {
  numOfItems: number
  onConfirm: Function
  onCancel: Function
  bundleListName: string
  open: boolean
  onSubmit: Function
}


export default function DeleteBundlesDialog(props: Props) {
  const {numOfItems,onConfirm, onCancel, bundleListName, open, onSubmit} = props

  const onClickingDelete = async () => {
    onConfirm()
    await onSubmit()
    onCancel()

  }
  return (
    <div >
      <Dialog open={open} onClose={onCancel}  sx={{
        '& .MuiDialog-paper': {
          width: '600px',
        },
      }}>
        <DialogTitle>{`Are you sure you want to delete ${numOfItems} bundles from ${bundleListName}? `}</DialogTitle>
        <DialogActions sx={{alignItems:"flex-end"}}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="submit" onClick={onClickingDelete} variant="contained" color="error" >
            Delete
          </Button>
        </DialogActions>
</Dialog>
    </div>
)
}
