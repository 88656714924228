import { Button, FormControl, Grid, IconButton, SxProps, Typography } from "@mui/material";
import { ReactElement, useContext, useState } from "react";
import  AddIcon  from "@mui/icons-material/Add";
import SearchField from "./SearchField";
import { ActionsCell } from "./ActionsCell";
import { ExportListDropDow } from "./ExportListDropDown";
import { BundleListNameCell } from "./BundleListNameCell";
import {Check, Edit} from '@mui/icons-material';
import {BundleList} from "../BundleListForm/BundleList";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import * as React from "react";

interface BeforeBundlesTableProps {
  sx?: SxProps
  children?: ReactElement[]
  placeholder?: string
  buttonOnClick?: () => void
  bundleList: BundleList
  onFilterChange?: (filter: string) => void;
  control: any
  getValues: Function
  setValue: Function;
  onSubmit: Function
}

const BeforeBundlesTable = (props: BeforeBundlesTableProps) => {
  const {placeholder,buttonOnClick,bundleList, onFilterChange, setValue, onSubmit} = props
  const [filter, setFilter] = useState<string>('')
  const lastUpdate = new Date(bundleList[ "last-update"])
  const [isEditingBundleName, setIsEditingBundleName] = useState(false)
  const handleFilterChange = (value: string) => {
        setFilter(value);
        onFilterChange?.(value);
    };
    const handleEditClick = () => {
        setIsEditingBundleName(true);
    };
   const handleSaveBundleListName = async (value) => {
     setValue("bundleList.bundle-list-name", value)
     await onSubmit()
     setIsEditingBundleName(false)
   }

  const formatDate = (isoDate) => {
    if (isoDate) {
      return dayjs.utc(isoDate).format('DD-MM-YYYY');
    }
    return ''
  };

  return (
      <>
          <Grid container justifyContent={"space-between"} direction="row" alignItems={"flex-end"} style={{padding: 10}}>
              <Grid item xs={3} sm={6}  >
                  {isEditingBundleName ? (
                      <Controller
                          name="bundleList.bundle-list-name"
                          control={props.control}
                          rules={{
                            required: "Bundle List  Name is required",
                            minLength: { value: 3, message: "Minimum length is 3" },
                            maxLength: { value: 100, message: "Maximum length is 100" }
                          } as const}
                          render={({ field, fieldState }) => {
                           return  (
                              <FormControl  margin="normal">
                                <Grid item display={"flex"}>
                                <TextField
                                  style= {{
                                    fontSize: 'inherit',
                                    width: 'auto',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                  {...field}
                                  value={field.value}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error ? fieldState.error.message : ""}
                                  variant="standard"
                                />
                                <IconButton type="submit" onClick={() => handleSaveBundleListName(field.value)}><Check /></IconButton>
                              </Grid>
                            </FormControl>
                            )}}
                        />
                  ) : (
                      <Grid container direction="row">
                              <Typography variant="body1" fontWeight="500"  style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                              }} >
                                  {bundleList["bundle-list-name"]}
                              </Typography>
                              <IconButton onClick={handleEditClick} style={{padding:0, marginLeft: 10}}><Edit /></IconButton>
                      </Grid>
                  )}
              </Grid>
              <Grid  xs={12} sm={6}  container justifyContent="flex-end"  spacing={1}>
                  <Grid item>
                      <SearchField
                          size="small"
                          padding={"0px"}
                          height={"42px"}
                          placeholder={`${placeholder}`}
                          shortcutKeys={['/']}
                          value={filter}
                          onChange={handleFilterChange}
                      />
                  </Grid>
                  <Grid item>
                      <ExportListDropDow bundles={bundleList["current-bundles"].map((bundleItem) => bundleItem.bundle)}/>
                  </Grid>
                  <Grid item>
                      <Button onClick={buttonOnClick} variant={"contained"} color={"primary"}>
                          <AddIcon />
                          {`Add Bundle IDs`}
                      </Button>
                  </Grid>
                  <Grid item>
                      <ActionsCell row={bundleList} actionsNames={["Duplicate Bundle List", "Clear Bundle List"]} callbackActions={[() => { }, () => { }]} />
                  </Grid>
              </Grid>
          </Grid>
          <Grid item style={{ paddingLeft: "10px" }}>
              <BundleListNameCell  createdBy={bundleList["created-by"]} id={bundleList["bundle-list-id"]} date={formatDate(lastUpdate)} />
          </Grid>
</>
  )
}


export default BeforeBundlesTable
