import { AppContract } from "@dtx/ui-core/src/AppContract";
import main from "@dtx/ui-core/src/main";
import { AsyncStore } from "@dtx/ui-core/src/query";
import { mockBrowser } from "@dtx/ui-core/src/mock/browser";
import { mockedPaths } from "./Mocks";
import { ReactNode } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ScreenWrapper from "./comps/shared/ScreenWraper";
import BundleListTable from "./comps/bundleListTable/BundleListTable";
import BundlesTable from "./comps/bundleListTable/BundlesTable";
import DealListTable from "./comps/dealTable/DealListTable";
import { DealForm } from "./comps/dealsForm/dealForm";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { theme } from '@dtx/ui-core/src/styled/Theme'
import { ThemeProvider } from "@mui/material";
import * as React from "react";
import { AuthProvider } from "./AuthProvider";

export class ContractProvider implements AppContract {
	private store: AsyncStore | null = null;

	onAsyncStore(store: AsyncStore) {
		this.store = store;
	}

  getBundleLists() {
		 const bundles = this.store!.addQuery("bundleList", async () => {
			const response = await fetch("/bundleList");
			const data = await response.json();
			return data
		})
		const data = bundles.data ?? []
		return data.map((list) => list.bundleListName)
	}
	returnsWrappedComponent(children: ReactNode,menuItems: any[]) {

		return	(<ScreenWrapper menuItems={menuItems}>
			{children}
		</ScreenWrapper>)
	}
	private menuItems = [{name:"Deals", id:"deals"}, {name:"Bundle Lists",id: "bundle-lists"}, {name:"IP Lists",id: "ip-lists"}]
	createApplicationComponent() {

		return (): JSX.Element => (
			<AuthProvider>
			<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Routes>
							<Route
								index
								path="/"
								element={this.returnsWrappedComponent(
									<></>,
									this.menuItems
								)}
							/>
							<Route
								path="/deals"
								element={this.returnsWrappedComponent(
									<DealListTable  store={this.store}/>,
									this.menuItems
								)}
							/>
							<Route
								path="/deals/edit"
								element={this.returnsWrappedComponent(
									<DealForm   deal={null} store={this.store} />,
									this.menuItems
								)}
							/>
							<Route
								path="/deals/edit/:id"
								element=
											{this.returnsWrappedComponent(
												<DealForm  deal={ null} store={this.store} />,
												this.menuItems
											)}
							/>
							<Route
								path="/bundle-lists"
								element={this.returnsWrappedComponent(
									<BundleListTable  store={this.store} />,
									this.menuItems								)}
							/>
							<Route path="/bundle-lists/:id"  element={this.returnsWrappedComponent(<BundlesTable  store={this.store}/>, this.getBundleLists())} />
						</Routes>
			</LocalizationProvider>
				</ThemeProvider>
			</AuthProvider>

		);
	}
}

export interface TheAppProps {
	store: AsyncStore
}



await mockBrowser.start(mockedPaths());
main(new ContractProvider());
