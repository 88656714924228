import React, { useContext, useEffect, useState } from "react";
import { Grid, Paper, TableBody, TableCell, TableRow } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AsyncQueryStatus, AsyncStore, AsyncStoreResult } from "@dtx/ui-core/src/query";
import { BundleList } from "../BundleListForm/BundleList";
import BeforeBundlesTable from "./BeforeBundlestable";
import BundlesTableAppBar from "./bundlesTableAppBar";
import { FormProvider, useForm } from "react-hook-form";
import Table from "../bundleListTable/Table";
import { SnackbarNotificationsContext } from "@dtx/ui-core/src/context/SnackbarNotificationsProvider";
import DeleteBundlesDialog from "./DeleteBundlesDialog";

interface Props {
  filter?: string;
  store: AsyncStore;
}

interface BundleListFormProps {
  bundleList: BundleList | null;
  editing: boolean;
  resolvedStrategies: string[];
  customMarginError: string | null;
  store: AsyncStore;
}

interface DataInterface {
  data: BundleList
}

export default function BundlesTable(props: Props) {
  const [numOfSelectedRows, setNumOfSelectedRows] = useState(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const location = useLocation();
  const editedBundleList = location.state?.bundleList;
  const [filter, setFilter] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  const { showNotification } = useContext(SnackbarNotificationsContext);
  let searchTimeOutId: number = -1;
  const [openDeleteBundlesDialog, setOpenDeleteBundlesDialog] = useState(false);

  useEffect(() => {
    result.refetch();
  }, [refreshKey]);
  const key = `bundle-list-${editedBundleList["bundle-list-id"]}-${filter}}`;
  const result: AsyncStoreResult<DataInterface | null> = props.store.addQuery(
    key,
    async () => {
      const encodedFilter = encodeURIComponent(filter);
      const response = await fetch(`/api/bundle-lists/get-bundle-list-by-id?bundleListId=${editedBundleList["bundle-list-id"]}&filters=${encodedFilter}`, {
        method: 'get',
        mode: 'cors',
      });
      if (response && response.status === 200) {
        const json = await response.json();
        return json;
      }
      throw new Error('Error fetching data');
    }
  );

  const methods = useForm<BundleListFormProps>({
    mode: 'onChange',
    defaultValues: {
      bundleList: result.data,
      editing: true,
      resolvedStrategies: [],
      customMarginError: null,
    }
  });

  const { control, reset, getValues, handleSubmit, setValue, watch, trigger, formState: { errors } } = methods;
  const bundleListId = watch("bundleList.bundle-list-id");
  const bundleListName = watch("bundleList.bundle-list-name");
  const bundleListCreatedBy = watch("bundleList.created-by");
  const bundleListLastUpdate = watch("bundleList.last-update");
  const bundleListCurrBundles = watch("bundleList.current-bundles");
  const bundleListAddedBundles = watch("bundleList.added-bundles");
  const bundleListDeletedBundles = watch("bundleList.deleted-bundles");


  const clearSearchDebounce = () => {
    if (searchTimeOutId != -1) {
      clearTimeout(searchTimeOutId);
      searchTimeOutId = -1;
    }
  };



  const handleFilterChange = (value) => {
    clearSearchDebounce()
    searchTimeOutId = setTimeout(function() {
      clearSearchDebounce()
      setFilter(value);
    }, 500)

  };


  const handleRowClick = (bundle: string) => {
    const toAddSelectedRows = !selectedRows.includes(bundle);
    setSelectedRows((prevSelectedRows) => {
      if (toAddSelectedRows) {
        return [...prevSelectedRows, bundle];
      } else {
        return prevSelectedRows.filter((row) => row !== bundle);
      }
    });

    setNumOfSelectedRows((prevNumOfSelectedRows) => prevNumOfSelectedRows + (toAddSelectedRows ? 1 : -1));
  };


  const onSubmit = async data => {
    const response = await fetch(`/api/bundle-lists/edit-bundle-list`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data.bundleList),
    });
    if (response.ok) {
      showNotification("success", "Bundle List saved successfully");
      await props.store.invalidateQuery(key)

    } else {
      showNotification("error", "Failed to save Bundle List");
    }
  };

  const onConfirmDeleteBundles = () => {
    const deletedBundles = getValues("bundleList.deleted-bundles")
    setValue("bundleList.deleted-bundles", deletedBundles.concat(selectedRows));
    console.log(bundleListDeletedBundles)
  };

  const columns = [
    {
      id: 'bundleId',
      size: 60,
      accessor: 'bundleId',
      header: '',
      cell: ({ row }) => {
        return row.original.bundle
      }
    },
  ];


  return (
    <Grid container>
      <Grid item style={{ paddingTop: "70px", paddingLeft: "20px", width: "90%" }}>
        <Grid item>
          {"Bundle List Details"}
        </Grid>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper>
                <>
                  {openDeleteBundlesDialog && numOfSelectedRows > 0 && (
                    <DeleteBundlesDialog
                      numOfItems={numOfSelectedRows}
                      onConfirm={onConfirmDeleteBundles}
                      onSubmit={handleSubmit(onSubmit)}
                      onCancel={() => setOpenDeleteBundlesDialog(false)}
                      bundleListName={result.data["bundle-list-name"]}
                      open={openDeleteBundlesDialog}
                    />
                  )}
                  <BeforeBundlesTable
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    onSubmit={handleSubmit(onSubmit)}
                    placeholder={"Search in this Bundle list"}
                    buttonOnClick={() => console.log("button add bundle list")}
                    bundleList={result.data ?? editedBundleList}
                    onFilterChange={handleFilterChange}
                  />
                  <Divider style={{ marginTop: 10 }} />
                  <BundlesTableAppBar
                    setOpenDeleteBundlesDialog={setOpenDeleteBundlesDialog}
                    control={control}
                    numOfSelected={numOfSelectedRows}
                    onClearSelection={() => {
                      setSelectedRows([]);
                      setNumOfSelectedRows(0);
                    }}
                    selectedBundles={selectedRows}
                  />
                  {result.status === AsyncQueryStatus.loading || result.isFetching || result.isPending ? (
                    <div id="load">
                      <div>G</div>
                      <div>N</div>
                      <div>I</div>
                      <div>D</div>
                      <div>A</div>
                      <div>O</div>
                      <div>L</div>
                    </div>
                  ) : result.status === AsyncQueryStatus.error || result.status === undefined ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                      There was an error. Please try again.
                    </div>
                  ) : (
                  <div style={{ overflowX: 'auto' }}>
                    <Table
                      id="bundle-table"
                      data={result.data["current-bundles"] ?? []}
                      columns={columns}
                      totalCount={result.data["current-bundles"] ? result.data["current-bundles"].length : 0}
                      totalPages={1}
                      currentPage={1}
                      rowPerPage={result.data["current-bundles"] ? result.data["current-bundles"].length : 0}
                      manualPagination={false}
                      paginationProps={{
                        manualPagination: false,
                        disabled: true,
                        pageSize: result.data["current-bundles"].length ?? 0,
                      }}
                      getRowProps={(row) => {
                        const bundleList = row.original;
                        return {
                          style: {
                            cursor: 'pointer',
                          },
                          onClick: () => handleRowClick(bundleList),
                        };
                      }}
                    />
                  </div>)}
                </>
            </Paper>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
}
