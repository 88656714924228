import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import CustomMenuItem from "./CustomMenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { styled } from "@mui/material/styles";
import {CopyText} from "../shared/CopyText";
import { Bundle } from "../BundleListForm/BundleList";


const StyledAppBar = styled(AppBar)`
  background-color: #0000;
  border-bottom: 1px solid #e1e1e1;
  box-shadow: none;
`;

interface BundlesTableAppbarProps {
  numOfSelected: number
  selectedBundles: Bundle[]
  onClearSelection: () => void
  control: any
  setOpenDeleteBundlesDialog: Function
}


function BundlesTableAppbar(props: BundlesTableAppbarProps) {
  const {onClearSelection, numOfSelected, selectedBundles, control, setOpenDeleteBundlesDialog} = props
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const bundlesNames = selectedBundles.map(bundleObj => bundleObj.bundle)

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
    const handleDownloadCSV = () => {
        const csvContent = "data:text/csv;charset=utf-8," + bundlesNames.map(bundle => `${bundle}`).join("\n")
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement("a")
        link.setAttribute("href", encodedUri)
        link.setAttribute("download", "bundles.csv")
        document.body.appendChild(link)
        link.click()
    }


  return (
    <StyledAppBar >
      <Container maxWidth="xl"  sx={{
          display: { margin:0, padding: 0 },
      }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              keepMounted
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none', margin:0 ,maxWidth:"xl"},
              }}
            >
            </Menu>
          <Box>
            <CustomMenuItem  text={` ${props.numOfSelected} selected`} textColor={"#000000"}/>
            <CustomMenuItem text={"Clear Selection"} onClick={onClearSelection}/>
            <CustomMenuItem text={"Remove Selected IDs"} icon={<DeleteIcon/>} onClick={() => {setOpenDeleteBundlesDialog(true)}}/>
            <CustomMenuItem text={"Download selection as CSV"} icon={<DownloadIcon/>} onClick={handleDownloadCSV}/>
            <CustomMenuItem text={"Copy selected IDs"} icon={<CopyText text={bundlesNames.join('\n')}/>}/>
          </Box>
      </Container>
    </StyledAppBar>
  );
}
export default BundlesTableAppbar;
